import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled, { keyframes } from 'styled-components'
import { BreadCrumb, Section, Tags, ComingSoon } from '../components/Section'
import CaseStudies from "../components/CaseStudies"
import PrimaryLinkButton from "../components/Button/PrimaryLinkButton"
import Benifits from '../components/Benifits'
import OurSolution from "../sections/service/ourSolution"
import BrandsWeTrust from "../sections/service/brandWeTrusted"
import CustomerReiview from "../sections/service/customerReview"
import Faq from "../sections/service/faq"
import LetastArticles from "../components/LetastArticles"
import GetStarted from '../components/GetStarted'
import BreakpointDown from '../components/Media/BreakpointDown'
import BreakpointUp from "../components/Media/BreakpointUp"
import { StaticImage } from "gatsby-plugin-image"
import PlusIcon from '../components/Icons/PlusIcon'
import DotteLineArrowIcon from '../components/Icons/DotteLineArrowIcon';
import SecondaryLinkButton from "../components/Button/SecondaryLinkButton"


const MonitoringSolutions = styled.div`
  display:flex;
  margin-left:-15px;
  margin-right:-15px;
  flex-wrap:wrap;
  min-height:800px;
  padding-bottom: 44px;
  position:relative;
  flex-direction:wrap;
  ${BreakpointDown.xl` 
    padding-bottom:50px;
    min-height:auto;
  `}
  ${BreakpointDown.lg`
      padding-bottom:20px;
  `}
   ${BreakpointDown.md`
      padding-bottom:20px;
  `}
`
const MonitoringSolutionsItem = styled.div` 
  flex:0 0 50%;
  width:50%;
  padding-left:15px;
  padding-right:15px;
  position:relative;
  &:last-child{
    display:none;
   ${BreakpointUp.xl`
     padding-left:0;
     display:block;
   `}
  }
&:first-child{
  padding-right:75px;
  padding-top:210px;
  ${BreakpointDown.xl` 
    padding-top:120px;
    min-height:auto;
  `}
  ${BreakpointDown.lg`
      padding-top:120px;
  `}
   ${BreakpointDown.md`
      padding-top:90px;
  `}
  ${BreakpointDown.md`
    padding-right:15px;
  `}
}
${BreakpointDown.xl` 
  flex:0 0 100%;
  width:100%;
`}
`
const ServiceTitle = styled.h1`
  /* max-width:445px; */
  margin-top:20px;
  margin-bottom:20px;
  line-height:48px;
 ${BreakpointDown.lg`
   line-height:inherit;
 `}
`
const MonitoringSolutionsItemContent = styled.p`
font-size:16px;
`
const MonitoringSolutionsBottomWrapper = styled.div`
 position:relative;
`

const MonitoringSolutionsBottom = styled.div`
  display:flex;
  flex:0 0 100%;
  left:0;
  right:0;
  position:absolute;
  bottom:-60px;
  font-size:20px;
  font-family: 'Comic Neue', cursive;
  z-index:2;
  height:120px;
  flex-wrap:wrap;
  margin-right: 60px;
  margin-left: 60px;
  @media (min-width:2400px){
    margin-left:200px;
    margin-right:200px; 
  }
  @media (max-width: 1024px) {
    margin-right: 0;
    margin-left: 0;
  }

  @media(max-width:1280px){
    bottom:0px; 
  }
  ${BreakpointDown.xl`
    position:static;
  `}
  ${BreakpointDown.md`
    height:auto;
  `}
`
const MonitoringSolutionsBottomLeft = styled.div`
flex: 0 0 50%;
  background:#529A00;
  padding:10px 45px;
  color:#FFFFFF;
  position:relative;
  ${BreakpointDown.lg`
    padding:10px 15px;
  `}
   ${BreakpointDown.md`
    flex: 0 0 100%;
  `}
&:after{
  position:absolute;
  right:-50px;
  top:50%;
  transform:translateY(-50%);
  content:'';
  width:80px;
  height: 2px;
  margin-top: 2px;
  background-image: linear-gradient(90deg, #fff, #fff 75%, transparent 0%, transparent 100%); 
  background-size: 20px 1px;
  border: none;
  ${BreakpointDown.md`
    display:none
  `}
}
`
const InnerText = styled.div`
  display:flex;
  align-items:center;
  height: 100%;
  justify-content:flex-end;
  ${BreakpointDown.md`
      line-height: 27px;
  `}
`
const MonitoringSolutionsBottomRight = styled.div`
  flex:0 0 50%;
  padding:15px 45px;
  background:#000000;
  @media(max-width:1280px){
    flex:0 0 50%;
  }
  ${BreakpointDown.lg`
    padding:15px 15px;
  `}
  ${BreakpointDown.md`
    flex: 0 0 100%;
  `}
`
const BtnWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    justify-content:center;
.btn{
  ${BreakpointUp.md`
   margin-left:40px; 
  `}
  padding:0;
  .text{
    color:#fff;
    letter-spacing: 3.2px;
    font-size:16px;
    ${BreakpointDown.lg`
      letter-spacing: 1.1px;
    `}
    &:before{
      background: #529A00;
    }
    &:after{
      background: #fff;
    }
  }
}
`
const ImgWrapper = styled.div`
 max-width:100%;
  height:100%;
 display:block;
 position: absolute;
right:15px;
 .gatsby-image-wrapper{ width:100%; height:100%;}
`
// our services
const OurSolutionTitle = styled.div`
 
`
const HeadingWrapper = styled.div`
 display:flex;
 margin-left:-15px;
 margin-right:-15px;
 flex-wrap:wrap;
 justify-content:space-between;
`
const HeadingItem = styled.div`
  padding-left:15px;
  padding-right:15px;
`
const OurSolutionList = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-top:40px;
 border-top:1px solid #CCCCCC;
 padding-top:40px;
 ${BreakpointDown.xl`
  padding-top:20px;
  padding-bottom:0;
  margin-top: 20px;
  text-align:center;
 `}
`
const OurSolutionListLeft = styled.div`
flex:0 0 600px;
position:relative;
${BreakpointDown.xl`
flex:0 0 100%;
text
 `}
&:before{
  content:'';
  width:120px;
  height:4px;
  background:#000;
  position:absolute;
  right:0;
  top:35px;
  margin-right:-60px;
  z-index:1;
  ${BreakpointDown.xl`
 display:none;
 `}
}
`
const OurSolutionListRight = styled.div`
flex:1;
padding-left:90px;
padding-top:20px;
${BreakpointDown.xl`
padding-left:0;
 `}
`
const OurSolutionListRightTitle = styled.div`
`
const OurSolutionListRightContent = styled.div`

`
// faq




const FadeIn = keyframes`
 from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const SectionBg = styled(Section)`
  position:relative;
  box-shadow: 0px 20px 85px #00000033;
  z-index: 2;
&:before, &:after{
  width:50%;
  ${BreakpointDown.xl`
    display:none;
    `
  }
}
.container{z-index:1}
`

const FaqTitle = styled.div`
 text-transform:uppercase;
 margin-bottom:0;
`

const FaqRow = styled.div`
 display:flex;
 flex-wrap:wrap;
 justify-content:space-around;
 align-items:center;
`
const FaqColumn = styled.div`
  flex: 0 0 50%;
  width:50%;
  position:relative;
  padding-top:150px;
  padding-bottom:150px;
  
  ${BreakpointDown.xl`
      flex: 0 0 100%;
      width:100%;
  `}
  &:first-child{
    border-right: 1px solid #00000033;
    box-shadow: 1px 0px 2px 0px #00000033;
    background:#fff;
    ${BreakpointDown.xl`
      padding-top:30px;
      padding-bottom:0px;
      border-right:0;
      box-shadow:none;
    `
  }
  }
  &:only-child{
    flex: 0 0 100%;
      width:100%;
      border-right:0;
      box-shadow:none
  }
  &:last-child{
    padding: 105px;
    ${BreakpointDown.xl`
        padding: 30px 0px;
      `
  }
   &:before{
     content:"?";
     font-size:800px;
     font-weight:900;
      position:absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
     font-family: "Nunito Sans", sans-serif;
     text-transform:uppercase;
     letter-spacing: -8px;
     color: #000000;
     opacity: 0.05;
     display:none;
     ${BreakpointDown.xl`
       display:none;
    `
  }
   }
  }
`
const TabList = styled.div` 
`
const TabListDesktop = styled(TabList)`
display:none;
${BreakpointUp.xl`
   display:inherit
`
  }
`
const TabListMobile = styled(TabList)`
display:none;
${BreakpointDown.xl`
   display:inherit
   `
  }
`
const TabListItem = styled.div`
padding-top:20px;
padding-bottom:20px;
display:flex;
justify-content:space-between;
align-items:center;
padding-left:20px;
padding-right:20px;
margin-bottom:0;
position: relative;
font-family: "Nunito Sans", sans-serif;
line-height: 24px;
font-weight: bold;
cursor: pointer;
${BreakpointDown.xl`
  flex-direction:column;
  background:#fff;
  border-bottom: 1px solid #00000033;
  padding-left:15px;
padding-right:15px;
 &:nth-of-type(){
  border-top: 1px solid #00000033;
 }
 `}
 ${BreakpointDown.md`
  padding-right:35px;
 `}

svg{
  transition: all 0.1s linear;
  position:absolute;
  right:0;
  ${BreakpointDown.md`
    right:-15px;
 `}
}
&:before{
    content:'';
    background:#F8F8F8;
    position:absolute;
    left:0;
    right:-40px;
    top:0;
    bottom:0;
    display:none;
    ${BreakpointDown.xl`
     right:0;
    `}
  }
&+&{
  border-top: 1px solid #00000033;
}
&.active{
  >div{
    color:#000;
  }
  svg{
    transform: rotate(45deg);
    path{
      fill:#000;
    }
  }
  .icon{
     display:block;
     ${BreakpointDown.xl`
    display:none;
    `}
  
  }
&:before{
  display:block;
}
}
`
const Text = styled.div`
  position:relative;
  z-index:1;
  display:flex;
  justify-content:space-between;
  align-items:center;
  width: 100%;
  color:#666666;
`
const Icon = styled.div`
  position:absolute;
  right:-30px;
  display:none;
  margin-top: 8px;
  transform: rotate(224deg);

 svg{
   margin-left:15px;
   path{
     fill:#000;
   }
 }
`
const TabContnet = styled.div`
  position:relative;
  z-index:1;
 
`
const TabContentItem = styled.div`
  animation-duration: 1s;
  animation-name: ${FadeIn};
  animation-fill-mode: both;
  transition: opacity 0.15s linear;
  &.tab-item{
    ${BreakpointUp.xl`
    display:none;
    `
  }
    &.active{
      ${BreakpointUp.xl`
        display:inherit;
        `
  }
    .tab-content-inner{
      padding-top:20px;
      height:auto;
      overflow:auto;
    }
    }
  }
`
const TabContentinner = styled.div`
   ${BreakpointDown.xl`
      height:0;
      overflow:hidden;
      transition: height 0.15s linear; 
    `
  }
`
//Letast BLogs


const Card = styled.div`
  padding: 70px 50px ;
  background:#fff;
  box-shadow: 0px 20px 45px rgb(0, 0, 0, 0.20);
  /* @media (min-width:2200px){
    padding: 20px 15px;
  } */
  @media(max-width:1440px){
    padding: 20px 14px; 
  }
  ${BreakpointDown.lg`
   text-align:center;
 `}
  .btn{
    margin-top:15px;
  }
`
const CardTitle = styled.div`
 margin-bottom:6px;
 font-weight:bold;
`
const CardImg = styled.div`
  position: relative;
  max-width:100%;
  width:100%;
  margin-top:26px;
  .gatsby-image-wrapper{
    width:100%;
  }
`
const Date = styled.div`
  color:#666666;
  font-size:10px;
  letter-spacing: 2px;
  text-transform: uppercase;
  @media (min-width:2200px){
    font-size:14px;
  }
`
const SectionHeader = styled.div`
 text-align:center;
 .h1{ margin-bottom:0px;}
 ${BreakpointDown.md`
 p{
   margin-bottom:0;
 } 
 `}
`
const Title = styled.h2`

`

const Blogs = styled.div`
 display: flex;
 margin-inline:-15px;
 flex-wrap:wrap;
 margin-top:50px;
`
const BlogItems = styled.div`
 flex:0 0 auto;
 width:33.3333%;
 padding-inline:15px;
 @media(max-width:767px){
   width:100%;
  & + &{
    margin-top:20px;
  }
 }
`

function ServicePage() {
  const [activeTab, setactiveTab] = useState(0);
  const HandleTabs = (key) => {
    setactiveTab(key);
  };
  return (
    <Layout pageName="service">
      <SEO title="Project Management Services for Equipment Installation - WYN Technologies"  description="WYN Technologies - providing end to end project management services for IT equipment and hardware installation with tailor made solutions. Contact us for the quote"/>
      <Section bgColor="#F8F8F8" pt="0" pb="0">
        <div className="container">
          <MonitoringSolutions>
            <MonitoringSolutionsItem>
              <BreadCrumb>
                <Link to="/">Home</Link><Link>Services</Link><Link to="/#">Project Management for Technology Equipment Installation</Link>
              </BreadCrumb>
              <ServiceTitle>Project Management for Technology Equipment Installation – New Sites, Site Remodels, & Site De-Installations</ServiceTitle>
              <MonitoringSolutionsItemContent>
              At <strong>WYN Technologies</strong>, we provide many one-off installations of helpful specialized applications designed to benefit your business operations. But thanks to our vast experience, resources, and know-how, we can do more than that. We also serve as technical installation consultants for new business sites, additional locations, and remodels. We facilitate the de-installation of legacy equipment which needs retiring, too. In short, we can help you develop the best plan of business technology implementation that maximizes both effectiveness and cost-effectiveness. We’ve handled large-scale, multi-site, and even national rollouts, so we know how to help you meet project deadlines and even exceed expectations!
              </MonitoringSolutionsItemContent>
            </MonitoringSolutionsItem>
            <MonitoringSolutionsItem>
              <ImgWrapper>
                <StaticImage
                  src="../images/project-management.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Letast Article"
                  placeholder="blurred"
                />
              </ImgWrapper>
            </MonitoringSolutionsItem>
          </MonitoringSolutions>
        </div>
      </Section>
      <MonitoringSolutionsBottomWrapper>
        <div className="container">
          <MonitoringSolutionsBottom>
            <MonitoringSolutionsBottomLeft>
              <InnerText>We ensure that you get the right technologies and best products</InnerText>
            </MonitoringSolutionsBottomLeft>
            <MonitoringSolutionsBottomRight>
              <BtnWrapper>
                <PrimaryLinkButton text="Solution You’re Looking!" />
              </BtnWrapper>
            </MonitoringSolutionsBottomRight>
          </MonitoringSolutionsBottom>
        </div>
      </MonitoringSolutionsBottomWrapper>
      <Benifits />
      <Section bgColor="#F8F8F8" pt="90px" pb="90px" xpt="60px" xpb="60px" mpt="40px" mpb="40px">
        <div className="container">
          <HeadingWrapper>
            <HeadingItem>
              <OurSolutionTitle className="h1">What We Provide with Our New Site & Remodel Equipment Installation & Legacy Equipment De-Installation Services</OurSolutionTitle>
              {/* <p>Lorem Ipsum is simply dummy text of the printing and type setting industry.</p> */}
            </HeadingItem>
          </HeadingWrapper>

          <OurSolutionList>
            <OurSolutionListLeft>
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </OurSolutionListLeft>
            <OurSolutionListRight>
              <OurSolutionListRightTitle className="h3">Site Surveys</OurSolutionListRightTitle>
              <OurSolutionListRightContent><p>Before we try to recommend a solution to take care of your new location(s) or remodeled site(s), we’ll start by taking the time to listen and gather some facts about your business. After you connect with us, we’ll want to learn more about you, how your business operates, what your facilities & properties are like, and what your goals are for implementing business technology. We’ll also schedule a time to come out and do a site survey in person. Whether you’re in the market for some new technology, or just need a seasoned technology equipment moving company, <strong>WYN</strong> can help!</p>
              </OurSolutionListRightContent>
            </OurSolutionListRight>
          </OurSolutionList>
          <OurSolutionList>
            <OurSolutionListLeft>
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </OurSolutionListLeft>
            <OurSolutionListRight>
              <OurSolutionListRightTitle className="h3">Quality Business Technology Solutions to Meet Your Needs</OurSolutionListRightTitle>
              <OurSolutionListRightContent><p>Need a partner who’s experienced and skilled at equipment moving and transfer? We’re here to help. Or perhaps you’re looking for some new, improved technology solutions for your new business construction or remodel site? We can certainly help with that, as well! No matter what your particular technology solution needs or budget are, and no matter the scope or size of your project, <strong>WYN Technologies</strong> can help you to both plan for and experience the perfect solution that really checks all of your boxes. <strong>WYN</strong> is your ideal partner for network solutions, surveillance systems, access control systems, business telephone systems, and more!</p></OurSolutionListRightContent>
            </OurSolutionListRight>
          </OurSolutionList>
          <OurSolutionList>
            <OurSolutionListLeft>
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </OurSolutionListLeft>
            <OurSolutionListRight>
              <OurSolutionListRightTitle className="h3">Sales, Installation, & Product Orientation</OurSolutionListRightTitle>
              <OurSolutionListRightContent><p>While it’s true that <strong>WYN Technologies</strong> is a preferred provider of quality products from top brands, including Samsung, Sharp, Cisco, TP-Link, Honeywell, Intermedia, Zultys, and more, we’re much more than just product vendors. Where we can really add value to your organization is in our ability to deliver true turnkey solutions. Whenever you partner with WYN, you’re really adding an expert technological consultant to your team. We can provide the business technology solution you need, and we’ll also take the time to make sure you understand how everything works. We’ll share best-practice tips for taking full advantage of your equipment, too.</p></OurSolutionListRightContent>
            </OurSolutionListRight>
          </OurSolutionList>
          <OurSolutionList>
            <OurSolutionListLeft>
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </OurSolutionListLeft>
            <OurSolutionListRight>
              <OurSolutionListRightTitle className="h3">Ongoing Support When You Need It</OurSolutionListRightTitle>
              <OurSolutionListRightContent><p>Have you ever had the unpleasant experience of partnering with a business technology consultant who left you feeling high-and-dry after the “job” was done? We’ve been on the receiving end of that ourselves, and it really isn’t much fun. Thankfully, <strong>WYN Technologies</strong> doesn’t treat you that way! We’ll be here for you long after your equipment is delivered, installed, and set up. We also provide “service after the sale” contracts you can take advantage of if you want us to maintain everything keep it in good working order for your business. Got a question or a problem? Just reach out!</p></OurSolutionListRightContent>
            </OurSolutionListRight>
          </OurSolutionList>
        </div>
      </Section>
      <BrandsWeTrust />
      <CustomerReiview />
      <CaseStudies />
      <GetStarted />
      <SectionBg bgBefore="#fff" bgAfter="#fff">
        <div className="container">
          <FaqRow>
            <FaqColumn>
              <FaqTitle className="h1">faq</FaqTitle>

              <TabListDesktop>
                {[
                  {
                    title: 'What IT elements do I need for my new business location?',
                  },
                  {
                    title: 'How can I help my business technology equipment installation to go more smoothly?',
                  },
                  {
                    title: 'What are some good tips for facilitating a multi-site technology rollout?',
                  },
                  {
                    title: 'How can WYN Technologies help?',

                  }
                ].map((item, index) => (<TabListItem key={index} onClick={() => HandleTabs(index)} className={`${activeTab === index ? "active" : ""}`}><Text>{item.title} <PlusIcon /> <Icon className="icon"><DotteLineArrowIcon /></Icon></Text>
                </TabListItem>
                ))}
              </TabListDesktop>

            </FaqColumn>
            <FaqColumn>
              <TabContnet>
                {[
                  {
                    title: 'What IT elements do I need for my new business location?',
                    content: 'No matter the size of your business, you’ll still need to plan for appropriate use of information technology (IT). Modern businesses really can’t function and compete without leveraging modern technology for business! Some of the basics you’ll need to plan for include your business network infrastructure, a server room, an internet provider, routers and access points, workstations, and your business telephone/communications system. Most businesses will also benefit from implementing a surveillance and security system.'
                  },
                  {
                    title: 'How can I help my business technology equipment installation to go more smoothly',
                    content: `Depending upon the nature of your business or commercial facility, there are several things you should think through that will help make the installation process smoother. Here’s a short list of questions you should ask yourself:
                    <ul>
                    <li>Are there any extreme environmental conditions in the vicinity of your planned equipment install locations?</li>
                    <li>Does any of your equipment require specific kinds of power sourcing or infrastructural support?</li>
                    <li>Will you need to relocate any piping or cabling?</li>
                    <li>Is the lighting adequate for your intended applications?</li>
                    <li>Will you need to hire an external contractor for any special needs or considerations?</li>
                    </ul>
                    `
                  },
                  {
                    title: 'What are some good tips for facilitating a multi-site technology rollout?',
                    content: 'Let’s say you’ve chosen all of your equipment products and have signed the contract with your chosen vendor/provider. What comes next? The actual installation and deployment, of course! What are some good tips for planning for your multi-site technology rollout? One of the first steps is to make sure your internal project manager is onboard with everything and ready to go. You’ll also want to make sure your technology partner has a thorough understanding of the project’s scope and desired outcomes. And if you’re managing several sites, it’s a good idea for you to do some individual site surveys on your own beforehand – that’s one of the best ways to anticipate and prevent any unforeseen surprises where your rollout is concerned.'
                  },
                  {
                    title: 'How can WYN Technologies help?',
                    content: 'A better question to ask is what can’t WYN help you with! When it comes to business technology solutions, we’ve really got you covered! We can provide everything from facility access security, to surveillance systems, to business telephone systems, to network/data/voice infrastructure setup, to ISP brokerage, to access point installation & wireless heat mapping. In addition, we can also install helpful technologies like interactive whiteboard, digital signage, infrared temperature monitoring stations, and GPS tracking systems. And if it’s a multi-site technology rollout you need, we can help plan, design, and manage it for you. Just remember that <strong>WYN</strong> stands for Whatever You Need, and that’s exactly what we’re here to deliver!'
                  }
                ].map((item, index) => {
                  return (
                    <TabContentItem key={index} className={activeTab === index ? 'tab-item active' : 'tab-item'} >
                      <TabListMobile> <TabListItem key={index} onClick={() => HandleTabs(index)} className={`${activeTab === index ? "active" : ""}`}><Text>{item.title}<PlusIcon /> <Icon className="icon"><DotteLineArrowIcon /></Icon></Text>
                      </TabListItem></TabListMobile>
                      <TabContentinner className="tab-content-inner">
                        <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
                      </TabContentinner>
                    </TabContentItem>
                  )
                })}
              </TabContnet>
            </FaqColumn>
          </FaqRow>
        </div>
      </SectionBg>
      <Section pt="80px" mpt="40px" bgColor="#F8F8F8" pb="50px" mpb="40px" xxpt="100px" xxpb="100px">
        <div className="container">
          <SectionHeader>
            <Title className="h1">Latest Articles & Blogs</Title>
          </SectionHeader>
          <ComingSoon textAlign="center">Coming Soon...</ComingSoon>
          {/* <Blogs>
            <BlogItems>
              <Card>
                <Date>24 June, 2020</Date>
                <CardTitle className="h6">We were recently featured on the local WXII news channel with regard to Thermal Temperature Monitoring.</CardTitle>
                <Tags to="/">Surveillance System</Tags> <Tags to="/">Thermal Monitoring</Tags>
                <CardImg>
                  <StaticImage
                    src="../../images/article-1.jpg"
                    quality={100}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Letast Article"
                    placeholder="blurred"
                  />
                </CardImg>
                <Link to="/"><SecondaryLinkButton text="Learn More" /></Link>
              </Card>
            </BlogItems>
            <BlogItems>
              <Card>
                <Date>24 June, 2020</Date>
                <CardTitle className="h6">We were recently featured on the local WXII news channel with regard to Thermal Temperature Monitoring.</CardTitle>
                <Tags to="/">Surveillance System</Tags> <Tags to="/">Thermal Monitoring</Tags>
                <CardImg>
                  <StaticImage
                    src="../../images/article-1.jpg"
                    quality={100}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Letast Article"
                    placeholder="blurred"
                  />
                </CardImg>
                <Link to="/"><SecondaryLinkButton text="Learn More" /></Link>
              </Card>
            </BlogItems>
            <BlogItems>
              <Card>
                <Date>24 June, 2020</Date>
                <CardTitle className="h6">We were recently featured on the local WXII news channel with regard to Thermal Temperature Monitoring.</CardTitle>
                <Tags to="/">Surveillance System</Tags> <Tags to="/">Thermal Monitoring</Tags>
                <CardImg>
                  <StaticImage
                    src="../../images/article-1.jpg"
                    quality={100}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Letast Article"
                    placeholder="blurred"
                  />
                </CardImg>
                <Link to="/"><SecondaryLinkButton text="Learn More" /></Link>
              </Card>
            </BlogItems>
          </Blogs> */}
        </div>
      </Section>
    </Layout>
  )
}

export default ServicePage
